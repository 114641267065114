import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useStyles from "./style";
import {
  deleteExchangeById,
  getExchangeList,
  rpcStatusUpdate,
} from "../../request/exchange-list";
import { RPCListResponse } from "../RPCListing/rpc.interface";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { useHistory, withRouter } from "react-router-dom";

const ExchangeListing = () => {
  const [exchangeList, setExchangeList] = useState([]);
  let history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();


  console.log(">>>>>>>>>>>>>>>", history)

  const fetchData = async () => {
    try {
      const response = await getExchangeList();
      if (response?.status === 200) {
        console.log("success", response);
        setExchangeList(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (rpcUrl: RPCListResponse) => {
    try {
      const isActive = rpcUrl?.isActive == 1 ? 0 : 1;
      const Id = rpcUrl?.id;
      const response = await rpcStatusUpdate(isActive, Id);
      fetchData();
    } catch (e) {}
  };

  const deleteWallet = async (exchangeList: any) => {
    try {
      if (!window.confirm("Are you sure?")) {
        return;
      }
      const response = await deleteExchangeById(exchangeList?.id);

      if (response?.status === 200) {
        dispatch(alertSuccess("Deleted Successfully"));
        fetchData();
      } else {
        dispatch(alertFailure("Error While Deleting Wallet"));
      }
    } catch (e) {
      dispatch(alertFailure("Error While Deleting Wallet"));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSwitchChange = async (id: number, currentStatus: number) => {
    try {
      const isActive = currentStatus === 1 ? 0 : 1;
      const response = await rpcStatusUpdate(isActive, id);
      if (response?.status === 200) {
        fetchData(); // Refresh the data after updating status
        dispatch(alertSuccess("Status updated successfully"));
      } else {
        dispatch(alertFailure("Failed to update status"));
      }
    } catch (error) {
      dispatch(alertFailure("An error occurred while updating status"));
      console.log(error);
    }
  };

  const tableHeaderData = [
    "#",
    "Name",
    "Icon",
    "Url",
    "enable/disable",
    "Actions",
  ];
  return (
    <DefaultLayout>
      {/* <Breadcrumb title="dashboard" breadcrumbItem="Exchange List" /> */}

      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "30px" }}
        onClick={() => {
          history.push("/exchange-list/add");
        }}
      >
        Add Exchange
      </Button>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeaderData.map((tHeadCell, index) => (
                <TableCell key={index} className={classes.tableHeader}>
                  {tHeadCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {exchangeList.map((exchange: any) => (
              <TableRow className={classes.tableRow} key={exchange.id}>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    {exchange.id}
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    {exchange?.name}
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    <img
                      width={40}
                      height={40}
                      alt={exchange.name}
                      src={exchange.logo}
                    ></img>
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    {exchange?.base_url}
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    <Switch
                      checked={exchange?.isActive === 1}
                      onChange={(e) =>
                        handleSwitchChange(exchange.id, exchange.isActive)
                      }
                    />
                  </span>
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      history.push(`/exchange-list/edit/${exchange?.id}`);
                    }}
                    disabled={!exchange?.isActive}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      deleteWallet(exchange);
                    }}
                    disabled={!exchange?.isActive}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </DefaultLayout>
  );
};


export default withRouter(ExchangeListing);
