import React, { useEffect } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Switch } from "antd";

function TonAddressToggle(props: any) {
  const classes = useStyles();
  const {
    setValue,
    errors,
    control,
    poolDetail,
    isEditDisabled
  } = props;
  const renderError = renderErrorCreatePool;

    useEffect(() => {
      if (poolDetail && poolDetail.is_custom_token) {
        setValue("is_custom_token", poolDetail.is_custom_token);

      }
    }, [poolDetail]);

  return (
    <>
      <div className={classes.formControl}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Hide/show Ton Address</label>

          <Controller
            control={control}
            name="is_custom_token"
            render={(field) => {
              const { value, onChange } = field;
              return (
                <Switch
                  onChange={async (switchValue) => {
                    const valueToSet = switchValue ? 1 : 0;
                    await onChange(switchValue);
                    setValue(field.name, valueToSet);
                  }}
                  style={{
                    width:"80px"
                  }}
                  checked={value}
                  checkedChildren="Show"
                  unCheckedChildren="Hide"
                  disabled={isEditDisabled}
                />
              );
            }}
          />
          <p className={classes.formErrorMessage}>
            {renderError(errors, "is_custom_token")}
          </p>
        </FormControl>
      </div>
    </>
  );
}

export default TonAddressToggle;
