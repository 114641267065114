import React, { useState } from "react";
import useStyles from "./style";
import { solanaConnectWallet, solanaDisconnectWallet, solanaSetWalletAddress } from "../../../store/actions/solana-wallet";
import { useDispatch } from "react-redux";

export default function WalletConnect() {
  const classes = useStyles();
  const [publicKey, setPublicKey] = useState("");
  const dispatch = useDispatch();
  return (
    <div>
      {!window?.solana?.publicKey && !publicKey ? (
        window.solana && window.solana.isPhantom ? (
          <button
            className={classes.button}
            onClick={async () => {
              const response = await window.solana.connect();
              dispatch(solanaConnectWallet());
                    dispatch(solanaSetWalletAddress(response.publicKey.toString()));
              setPublicKey(response.publicKey.toString());
            }}
          >
            Connect Solana Wallet
          </button>
        ) : (
          <p>No wallet found. Please download a supported Solana wallet</p>
        )
      ) : (
        <>
          <button
            onClick={async () => {
              await window.solana.disconnect();
              dispatch(solanaDisconnectWallet())
              setPublicKey("");
            }}
            className={classes.button}
          >
            Disconnect Solana Wallet
          </button>
        </>
      )}
    </div>
  );
}
