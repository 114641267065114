import React from "react";
import ConfirmDialog from "../../../../components/Base/ConfirmDialog";
import useStyles from "../../style";
import CurrencyInput from "react-currency-input-field";

function SolanaTokenDepositForm(props: any) {
  const classes = useStyles();
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    handleCreateUpdateData,
    setAmount,
    amount,
    disableButton,
    setTokenAddress,
    acceptCurrency
  } = props;

  const handleSubmitPopup = () => {
    handleCreateUpdateData();
  };

  return (
    <>
      <ConfirmDialog
        title={"Deposit Token"}
        open={isOpenEditPopup}
        confirmLoading={disableButton}
        onConfirm={handleSubmitPopup}
        onCancel={() => {
          setIsOpenEditPopup(false);
        }}
        btnLoading = {disableButton}
      >
        <div className={classes.formControl}>
          <div>
            <input
              type="text"
              style={{
                marginBottom: "10px",
              }}
              className={classes.formControlInput}
              placeholder={`Enter ${acceptCurrency?.toUpperCase() || ""} Token Account`}
              onChange={(e) => {
                setTokenAddress(e.target.value);
              }}
            />
          </div>
          <div>
            <CurrencyInput
              id="token_amount"
              placeholder="Please enter amount"
              value={amount}
              decimalsLimit={2}
              onValueChange={(e: any) => setAmount(e)}
              className={`${classes.formInputBox}`}
              style={{ width: "100%" }}
              // disabled={isDeployed}
            />
            <input type="hidden" name="token_amount" value={amount || ""} />
          </div>
        </div>
      </ConfirmDialog>
    </>
  );
}

export default SolanaTokenDepositForm;
