import React, { useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import useStyles from "./styles";
import { adminRoute } from "../../../utils";
import { logout } from "../../../store/actions/user";
import { useDispatch } from "react-redux";
import { ADMIN_LOGS, BLOG, SETTING_CONFIG, HIDE_STATISTIC, RPC, SOLANA_MULTI_SIG, TIERS_RANGE, WHITELABEL_CONFIG, WHITELIST_WALLETS_TAB, EXCHANGE_LIST, MAX_CAP } from "../../../constants";

const nav = [
  {
    title: "List Pool",
    part: adminRoute("/campaigns"),
    icon: "icon-list-campaign",
    show: true,
  },
  // {
  //   title: "List TBA Pool",
  //   part: adminRoute("/tba-campaigns"),
  //   icon: "icon-list-campaign",
  // },
  {
    title: "List User",
    part: adminRoute("/users"),
    icon: "icon-users",
    show: true,
  },
  // {
  //   title: "User Referral",
  //   part: adminRoute("/referrals"),
  //   icon: "icon-history",
  // },
  {
    title: "KYC User List",
    part: adminRoute("/kyc-users"),
    icon: "icon-users",
    show: true,
  },
  {
    title: "Staking Pools",
    part: adminRoute("/staking"),
    icon: "icon-list-campaign",
    show: true,
  },
  {
    title: "List Admin",
    part: adminRoute("/admins"),
    icon: "icon-users",
    show: true,
  },
  {
    title: "Setting",
    part: adminRoute("/setting"),
    icon: "icon-setting",
    show: RPC,
    submenu: SETTING_CONFIG
      ? [
          {
            title: "Config",
            part: adminRoute("/config"),
            icon: "icon-setting",
            show: RPC,
          },
          {
            title: "Exchange Lists",
            part: adminRoute("/exchange-list"),
            icon: "icon-setting",
            show: EXCHANGE_LIST,
          },
          {
            title: "Tiers Range",
            part: adminRoute("/tiers-range"),
            icon: "icon-rpc",
            show: TIERS_RANGE,
          },
          {
            title: "RPC URLs",
            part: adminRoute("/rpc-url"),
            icon: "icon-rpc",
            show: RPC,
          },
          {
            title: "Admin Logs",
            part: adminRoute("/admin-logs"),
            icon: "icon-users",
            show: ADMIN_LOGS,
          },
          {
            title: "Solana Multi Sig",
            part: adminRoute("/solana-multi-sig"),
            icon: "icon-rpc",
            show: SOLANA_MULTI_SIG,
          },
          {
            title: "WhiteList Wallets",
            part: adminRoute("/white-list-wallets"),
            icon: "icon-rpc",
            show: WHITELIST_WALLETS_TAB,
          },
          {
            title: "Blogs",
            part: adminRoute("/blog"),
            icon: "icon-rpc",
            show: BLOG,
          }
        ]
      : [],
  },
];

const NavLeft = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { smallLeft, location } = props;
  const [navLeft] = React.useState(nav);
  const history = useHistory();

  const [openMenu, setOpenMenu] = useState(false);

  const logoutUser = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you want logout?")) {
      return false;
    }
    dispatch(logout());
    history.push(adminRoute("/login"));
  };

  return (
    <ul className={styles.navLeft}>
      {navLeft
        .filter((item) => {
          if (item.show) return item;
        })
        .map((item, index) => {
          return (
            <div key={index}>
              <li
                key={index}
                className={
                  styles.itemNavLeft +
                  " " +
                  (location?.pathname === item.part && "active")
                }
              >
                {item?.submenu && item?.submenu?.length > 0 ? (
                  <div
                    onClick={() => {
                      setOpenMenu(!openMenu);
                    }}
                    className={
                      styles.linkItemNavLeft +
                      " " +
                      (location?.pathname === item.part && "active")
                    }
                  >
                    <i
                      className={
                        (smallLeft && " icon-small") + " icon " + item.icon
                      }
                    ></i>
                    {!smallLeft && item.title}
                  </div>
                ) : (
                  <Link
                    onClick={() => {
                      // if (item?.submenu) {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      // }
                    }}
                    to={item.part}
                    className={
                      styles.linkItemNavLeft +
                      " " +
                      (location?.pathname === item.part && "active")
                    }
                  >
                    <i
                      className={
                        (smallLeft && " icon-small") + " icon " + item.icon
                      }
                    ></i>
                    {!smallLeft && item.title}
                  </Link>
                )}
              </li>
              {item?.submenu && item?.submenu?.length > 0 && openMenu && (
                <ul>
                  {item.submenu.map((subMenu, index) => (
                    subMenu?.show &&<li
                      onClick={() => {
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                      key={index}
                      className={
                        styles.itemNavLeft +
                        " " +
                        (location?.pathname === subMenu.part && "active")
                      }
                      style={{
                        marginLeft: "50px",
                        width: "calc(100% - 50px)",
                      }}
                    >
                      <Link
                        to={subMenu.part}
                        className={
                          styles.subItemNavLeft +
                          " " +
                          (location?.pathname === subMenu.part && "active")
                        }
                      >
                        {subMenu.icon && (
                          <i
                            className={
                              (smallLeft && " icon-small") +
                              " icon " +
                              subMenu.icon
                            }
                          ></i>
                        )}
                        {!smallLeft && subMenu.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          );
        })}

      <li className={styles.itemNavLeft}>
        <a onClick={logoutUser} className={styles.linkItemNavLeft}>
          <i
            className={(smallLeft && " icon-small") + " icon icon-setting"}
          ></i>
          {!smallLeft && "Logout"}
        </a>
      </li>
    </ul>
  );
};

export default withRouter(NavLeft);
