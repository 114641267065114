import React, { useEffect, useState } from "react";
import { Button, TextField, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import {
  addExchangeList,
  getExchangeListById,
  updateExchangeById,
} from "../../request/exchange-list";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import ImageUploader from "../PoolCreate/Components/ImageUploader";
import { useHistory, useParams, withRouter } from "react-router-dom";

const ExchangeListForm = () => {
  const [image, setImage] = useState("");
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      exchangeName: "",
      url: "",
    },
  });

  const handleFileSelect = (file: any) => {
    setImage(file);
  };

  const fetchData = async (id: number) => {
    try {
      const response = await getExchangeListById(id);
      if (response?.status === 200) {
        setValue("exchangeName", response.data.name);
        setValue("url", response.data.base_url);
        setImage(response.data.logo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: any) => {
    let response;
    try {
      if (id) {
        response = await updateExchangeById(
          { base_url: data.url, name: data.exchangeName, logo: image },
          Number(id)
        );
      } else {
        response = await addExchangeList({
          base_url: data.url,
          name: data.exchangeName,
          logo: image,
        });
      }

      if (response?.status === 200) {
        dispatch(alertSuccess("Added successfully"));
        history.push("/dashboard/exchange-list");
      } else if (response?.status === 400) {
        dispatch(alertFailure(response.message));
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <FormControl
          variant="outlined"
          margin="normal"
          fullWidth
          style={{ margin: "0" }}
        >
          <label htmlFor="exchange" style={{ marginBottom: "5px" }}>
            Exchange
          </label>
          <TextField
            style={{ margin: "0px" }}
            type="text"
            id="exchange"
            variant="outlined"
            margin="normal"
            fullWidth
            name="exchangeName"
            inputRef={register({ required: "Exchange name is required" })} // Register input with validation
            error={!!errors.exchangeName}
          />
          {errors.exchangeName && (
            <div style={{ color: "red" }}>{errors.exchangeName.message}</div>
          )}
        </FormControl>

        <FormControl
          variant="outlined"
          margin="normal"
          fullWidth
          style={{ marginTop: "10px" }}
        >
          <label
            htmlFor="url"
            style={{ marginTop: "5px", marginBottom: "8px" }}
          >
            URL
          </label>
          <TextField
            style={{ margin: "0px" }}
            type="text"
            id="url"
            variant="outlined"
            margin="normal"
            fullWidth
            name="url"
            inputRef={register({ required: "URL is required" })} // Register input with validation
            error={!!errors.url}
          />
          {errors.url && (
            <div style={{ color: "red" }}>{errors.url.message}</div>
          )}
        </FormControl>

        <Row style={{ marginBottom: "20px" }}>
          <Col className="col-12">
            <label className="form-label" style={{ marginBottom: "5px" }}>
              Image
            </label>
            <ImageUploader
              width="50%"
              onFileSelect={handleFileSelect}
              initialSrc={image}
            />
          </Col>
        </Row>

        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            history.push("/dashboard/exchange-list");
          }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </DefaultLayout>
  );
};

export default withRouter(ExchangeListForm);

// import React, { useEffect, useState } from "react";
// import DefaultLayout from "@/components/Layout/DefaultLayout";
// import {
//   getExchangeListById,
//   updateExchangeById,
//   addExchangeList,
//   exchangeListOptions,
// } from "@/request/exchange-list";
// import { alertFailure } from "@/store/actions/alert";
// import {
//   Button,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
// } from "@mui/material";
// import { useParams } from "next/navigation";
// import { useRouter } from "next/router";

// import { useDispatch } from "react-redux";
// import Breadcrumb from "@/components/Base/BreadCrumbs";
// import { Col, Row } from "reactstrap";
// import ImageUploader from "@/components/PoolCreate/Components/ImageUploader";

// const ExchangeWalletsForm = () => {
//   const [url, setUrl] = useState("");
//   const [exchangeName, setExchangeName] = useState(" ");
//   const [image, setImage]: any = useState("");
//   const [rpcUrl, setRpcUrl] = useState(); // Adjust the type if necessary
//   const router = useRouter();
//   const [errors, setErrors] = useState<any>({});
//   let { id } = router.query;
//   const dispatch = useDispatch();

//   const handleFileSelect = (file: any) => {
//     setImage(file);
//   };

//   const handleUrlChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (url) {
//       setErrors("");
//     }
//     setUrl(event.target.value);
//   };

//   const handleExchangeChange = (event: any) => {
//     if (exchangeName) {
//       setErrors("");
//     }
//     setExchangeName(event.target.value);
//   };

//   const fetchData = async (id: number) => {
//     try {
//       const response = await getExchangeListById(id);
//       console.log(response);
//       if (response?.status === 200) {
//         setUrl(response.data.base_url);
//         setExchangeName(response.data.name);
//         setImage(response.data.logo);
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
//     event.preventDefault();
//     const errors: any = {};
//     if (exchangeName === " ") {
//       errors.network = "Exchange is required";
//     }
//     if (!url) {
//       errors.url = "base_url  is required";
//     }
//     let response;
//     try {
//       if (Object.keys(errors).length === 0) {
//         if (id) {
//           response = await updateExchangeById(
//             { base_url: url, name: exchangeName, logo: image },
//             Number(id)
//           );
//         } else {
//           response = await addExchangeList({
//             base_url: url,
//             name: exchangeName,
//             logo: image,
//           });
//         }

//         if (response?.status === 200) {
//           router.push("/dashboard/exchange-list");
//         } else if (response?.status === 400) {
//           dispatch(alertFailure(response.message));
//         }
//       } else {
//         setErrors(errors);
//       }
//     } catch (error: any) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     if (id) {
//       fetchData(Number(id));
//     }
//   }, [id]);

//   return (
//     <DefaultLayout>
//       <Breadcrumb title="dashboard" breadcrumbItem="Edit Exchange" />

//       <form onSubmit={handleSubmit} noValidate autoComplete="off">
//         <FormControl variant="outlined" margin="normal" fullWidth>
//           <InputLabel id="exchange-label">Exchange</InputLabel>
//           <Select
//             labelId="exchange-label"
//             id="exchange"
//             value={exchangeName}
//             onChange={handleExchangeChange}
//             label="Exchange"
//             error={!!errors.network}
//           >
//             <MenuItem value=" " disabled>
//               Select a Exchange
//             </MenuItem>
//             {exchangeListOptions?.map((menu, index) => {
//               return (
//                 <MenuItem
//                   key={index + menu.id}
//                   value={menu.value.toLowerCase()}
//                 >
//                   {menu.label}
//                 </MenuItem>
//               );
//             })}
//           </Select>
//           {errors.network && (
//             <div style={{ color: "red" }}>{errors.exchange}</div>
//           )}
//         </FormControl>

//         <div>
//           <TextField
//             label="URL"
//             type="text"
//             id="url"
//             value={url}
//             onChange={handleUrlChange}
//             variant="outlined"
//             margin="normal"
//             fullWidth
//             error={!!errors.url}
//           />
//           {errors.url && <div style={{ color: "red" }}>{errors.url}</div>}
//         </div>

//         <Row
//           style={{
//             marginBottom: "20px",
//           }}
//         >
//           <Col className="col-12">
//             <label className="form-label">Image</label>
//             <ImageUploader
//               width="50%"
//               onFileSelect={handleFileSelect}
//               initialSrc={image}
//             />
//           </Col>
//         </Row>
//         <Button
//           variant="contained"
//           color="primary"
//           style={{ marginRight: "10px" }}
//           onClick={() => {
//             router.push("/dashboard/exchange-list");
//           }}
//         >
//           Back
//         </Button>
//         <Button type="submit" variant="contained" color="primary">
//           Submit
//         </Button>
//       </form>
//     </DefaultLayout>
//   );
// };

// export default ExchangeWalletsForm;
