import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import { Button } from "@material-ui/core";
import WithdrawTokenAccount from "./WithdrawTokenAccount";
import { renderErrorCreatePool } from "../../../../utils/validate";
import useStyles from "../../style";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../../utils";
import { BaseRequest } from "../../../../request/Request";

function SolanaWithdrawRemainingToken(props: any) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { poolDetail, disabled, permission } = props;
  const [loading, setLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState<any>();
  const [disableButton, setDisableButton] = useState<Boolean>(false);
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const renderError = renderErrorCreatePool;

  const withdrawRemaining = async () => {
    // eslint-disable-next-line no-restricted-globals
    if(!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    try {
      setLoading(true);
      setDisableButton(true);
      const baseRequest = new BaseRequest();
      let url = apiRoute(`/solana/refund-remaining-tokens`);
      const body = {
        campaign_id: poolDetail?.id,
        wallet: tokenAddress,
        fee_payer: window?.solana?.publicKey.toString(),
      };
      const response = (await baseRequest.post(url, body)) as any;
      const data = await response.json();
      const tx = await convertToTransaction(data?.data);
      const sig = await SendSolanaTransaction(tx);

      setLoading(false);
      dispatch(alertSuccess("Withdraw Success !!!"));
    } catch (e) {
      console.log("withdrawRemaining", e);
      setDisableButton(false);
      setLoading(false);
      dispatch(alertFailure("Withdraw Fail !!!"));
      return false;
    }
  };

  const openPopupCreate = (e: any) => {
    setIsOpenEditPopup(true);
  };

  return (
    <div style={{ marginTop: 30 }}>
      {isOpenEditPopup && (
        <WithdrawTokenAccount
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          renderError={renderError}
          handleCreateUpdateData={withdrawRemaining}
          disableButton={disabled || disableButton}
          setTokenAddress={setTokenAddress}
        />
      )}
      <div className={`${classes.formControl} ${classes.flexRow}`}>
        <Button disabled = {!permission} variant="contained" color="primary" onClick={openPopupCreate}>
          Withdraw Remaining Token
        </Button>
      </div>

      {loading && <div>Loading....</div>}
      {transactionHash && <div>Transaction Hash: {transactionHash}</div>}
    </div>
  );
}

export default SolanaWithdrawRemainingToken;
