import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";

export const getExchangeList = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/exchanges-url`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const addExchangeList = async (exchangeList: {
  name: string;
  base_url: string;
  logo: string;
}) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/exchanges-url`);
  const response = (await baseRequest.post(url, exchangeList)) as any;
  return await response?.json();
};

export const updateExchangeById = async (
  exchangeList: { name: string; base_url: string; logo: string },
  Id: number
) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/exchanges-url/${Id}`);
  const response = (await baseRequest.put(url, exchangeList)) as any;
  return await response?.json();
};

export const getExchangeListById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/exchanges-url/${Id}`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const deleteExchangeById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/exchanges-url/${Id}`);
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const rpcStatusUpdate = async (isActive: number, Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/exchanges-url/${Id}`);
  const response = (await baseRequest.patch(url, {
    isActive: isActive,
  })) as any;
  return await response?.json();
};

export const deleteRPCUrl = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/rpc-url/${Id}`);
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};

export const exchangeListOptions = [
  {
    id: 1,
    value: "Uniswap",
    label: "Uniswap",
  },
  {
    id: 2,
    value: "PancakeSwap",
    label: "PancakeSwap",
  },
  {
    id: 3,
    value: "kucoin",
    label: "KuCoin",
  },
  {
    id: 4,
    value: "HTX",
    label: "HTX",
  },
  {
    id: 5,
    value: "MEXC",
    label: "MEXC",
  },
  {
    id: 6,
    value: "Bybit",
    label: "Bybit",
  },
  {
    id: 7,
    value: "Bitget",
    label: "Bitget",
  },
  {
    id: 8,
    value: "Gate",
    label: "Gate",
  },
  {
    id: 9,
    value: "Bitmart",
    label: "Bitmart",
  },
  {
    id: 10,
    value: "BingX",
    label: "BingX",
  },
  {
    id: 11,
    value: "LBank",
    label: "LBank",
  },
  {
    id: 12,
    value: "Raydium",
    label: "Raydium",
  },
];
