import React, { useEffect } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Switch } from "antd";
import { FormControlLabel } from "@material-ui/core";

function TonDistribution(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.is_ton_distribution) {
      setValue("is_ton_distribution", poolDetail.is_ton_distribution);
    }
  }, [poolDetail]);

  return (
    <>
      <div>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Ton Distribution</label>

          <Controller
            control={control}
            rules={{}}
            name="is_ton_distribution"
            render={(field) => {
              const { value, onChange } = field;
              return (
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e) => {
                        const valueToSet = e ? 1 : 0;
                        setValue(field.name, valueToSet);
                        onChange(valueToSet);
                      }}
                      checked={!!value}
                      style={{marginLeft: "10px"}}
                    />
                  }
                  label=""
                  disabled = {isEditDisabled}
                />
              );
            }}
          />
          <p className={classes.formErrorMessage}>
            {renderError(errors, "is_ton_distribution")}
          </p>
        </FormControl>
      </div>
    </>
  );
}

export default TonDistribution;
