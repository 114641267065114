import React, { useEffect, useState } from "react";
import { TableCell, TableRow, Tooltip } from "@material-ui/core";
import useComponentVisible from "../../../hooks/useComponentVisible";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { alertSuccess, alertFailure } from "../../../store/actions/alert";

import useStyles from "./style";
import { UseCheckPermissions } from "../../../utils/useCheckPermissions";
import { deleteAdminLogs } from "../../../request/admin-logs";
import LogsDetailModal from "../LogsDetailModal";
import { ModuleConfigType, moduleConfig } from "./routingConfig";

const AdminLogsRow: React.FC<any> = (props: any) => {
  const { admin, currentOpen, setCurrentOpen, getAdminListInfo } = props;
  const classes = useStyles();
  const { ref, isVisible, setIsVisible } = useComponentVisible();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    currentOpen && setCurrentOpen("");
  }, [admin]);

  useEffect(() => {
    setIsVisible(admin.id === currentOpen);
  }, [currentOpen]);

  const handleDeleteUser = async () => {
    if (!window.confirm("Do you want delete this item?")) {
      return false;
    }
    const res = await deleteAdminLogs(admin.id);

    if (res.status === 200) {
      dispatch(alertSuccess("Delete Logs Success"));
      await getAdminListInfo();
    } else {
      dispatch(alertFailure("Delete Logs failed"));
    }
  };

  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);

  const openPopupCreate = () => {
    setIsOpenEditPopup(true);
  };

  const [logDetail, setLogDetail] = useState<any>("");

  const navigateBasedOnPoolId = (id: number | string, module_name: string) => {
    if (moduleConfig[module_name as ModuleConfigType].id) {
      history.push(moduleConfig[module_name as ModuleConfigType].url + id);
    } else {
      history.push(moduleConfig[module_name as ModuleConfigType].url);
    }
  };

  return (
    <>
      <TableRow ref={ref} className={classes.tableRow} key={admin.id}>
        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
          onClick={() => {
            history.push(`/dashboard/admin-detail/${admin.admin_id}`);
          }}
        >
          {admin.admins.firstname}
        </TableCell>

        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip title={<p style={{ fontSize: 15 }}>{admin.action}</p>}>
            <span className={classes.wordBreak}>{admin.action}</span>
          </Tooltip>
        </TableCell>

        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
          style={{
            verticalAlign: "middle",
          }}
        >
          <Tooltip title={<p style={{ fontSize: 15 }}>{admin?.module_name}</p>}>
            <div
              onClick={() => {
                navigateBasedOnPoolId(admin?.record_id, admin?.module_name);
              }}
            >
              <span className={classes.wordBreak}>{admin.module_name}</span>
            </div>
          </Tooltip>
        </TableCell>

        <TableCell
          className={classes.tableCellTitle}
          component="td"
          scope="row"
        >
          <Tooltip
            title={
              <p style={{ fontSize: 15 }}>
                {admin.module_name == "POOL" && admin.record_name
                  ? admin.record_name
                  : "_ _"}
              </p>
            }
          >
            <span
              className={classes.wordBreak}
              onClick={() => {
                if (admin.module_name == "POOL" && admin.record_name) {
                  navigateBasedOnPoolId(admin?.record_id, admin?.module_name);
                }
              }}
            >
              {admin.module_name == "POOL" && admin.record_name
                ? admin.record_name
                : "_ _"}
            </span>
          </Tooltip>
        </TableCell>

        <TableCell className={classes.tableCellTitle} align="left">
          <div className={classes.tableCellFlex}>
            <div className="left">
              <Tooltip
                title={<p style={{ fontSize: 15 }}>{admin.updated_at}</p>}
              >
                <span className={classes.wordBreak}>{admin.updated_at}</span>
              </Tooltip>
            </div>
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} align="left">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                setLogDetail(admin);
                openPopupCreate();
              }}
            >
              View
            </Button>
            {/* <Button
            variant="contained"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteUser();
            }}
          >
            Delete
          </Button> */}
          </div>
        </TableCell>
      </TableRow>
      {isOpenEditPopup && (
        <LogsDetailModal
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          logDetail={logDetail}
        />
      )}
    </>
  );
};

export default AdminLogsRow;
