import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import CustomAccordion from "./CustomAccordion";
import InputField from "./TextField";
import CustomButton from "./Button";
import useStyles from "./style";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../utils";
import { BaseRequest } from "../../../request/Request";
import { Keypair } from "@solana/web3.js";

export default function TokenData({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  owner,
  disableButton
}: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [rate, setRate] = useState(0);
  const [decimals, setDecimals] = useState<number>(0);
  const [fundingAccount, setFundingAccount] = useState("");
  const [tokenMint, setTokenMint] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<any>(false);

  const handleRateChange = (e: any) => {
    if (rate) {
      setErrors((prevErrors: any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setRate(e.target.value);
  };

  const handleDecimalChange = (e: any) => {
    if (decimals) {
      setErrors((prevErrors: any) => {
        const { decimals, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setDecimals(e.target.value);
  };

  const handleFundingAccountChange = (e: any) => {
    if (fundingAccount) {
      setErrors((prevErrors: any) => {
        const { fundingAccount, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setFundingAccount(e.target.value);
  };

  const handleTokenMintChange = (e: any) => {
    if (tokenMint) {
      setErrors((prevErrors: any) => {
        const { tokenMint, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
    setTokenMint(e.target.value);
  };

  useEffect(() => {
    if (tokenMint) {
      setErrors((prevErrors: any) => {
        const { tokenMint, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [tokenMint]);

  useEffect(() => {
    if (rate) {
      setErrors((prevErrors: any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [rate]);

  useEffect(() => {
    if (rate > 0) {
      setErrors((prevErrors: any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [rate]);

  useEffect(() => {
    if (decimals) {
      setErrors((prevErrors: any) => {
        const { decimals, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [decimals]);

  useEffect(() => {
    if (fundingAccount) {
      setErrors((prevErrors: any) => {
        const { fundingAccount, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [fundingAccount]);

  const setOfferedTokenData = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    if(!pool_index) {
      dispatch(alertFailure("Pool is not deployed"))
      return;
    }
    if (!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"));
      return;
    }
    if (!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"));
      return;
    }
    if(owner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    const errors: any = {};
    if (!rate) {
      errors.rate = "Rate is required";
    }
    if (rate <= 0) {
      errors.rate = "Rate must be greater than zero";
    }
    if (!decimals) {
      errors.decimals = "Decimals is required";
    }
    if (!fundingAccount) {
      errors.fundingAccount = "Funding Account is required";
    }
    if (!tokenMint) {
      errors.tokenMint = "Token Mint is required";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true)
      try {
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        var tokenAccountKeypair = Keypair.generate();
        let url = apiRoute(`/solana-multisig/set-offered-token-data`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address, 
          campaign_id: poolId, 
          rate: rate,
          decimals,
          token_mint: tokenMint,
          funding_account: fundingAccount,
          fee_payer: window?.solana?.publicKey.toString(),
          token_account_publickey: tokenAccountKeypair.publicKey
      }
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data)
        // const sig = await SendSolanaTransaction(tx, [tokenAccountKeypair]);
      const sig = await SendSolanaTransaction(tx, [tokenAccountKeypair]);
        dispatch(alertSuccess("Transaction Successfull"));
        setTokenMint("");
        setRate(0);
        setDecimals(0);
        setFundingAccount("");
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction failed"));
        setTokenMint("");
        setRate(0);
        setDecimals(0);
        setFundingAccount("");
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };
  return (
    <div className={classes.spaceAbove}>
      <CustomAccordion title="Set Offered Token Data">
        <div className={classes.columnFlex}>
          <InputField
            label="Enter Token Mint"
            id="tokenMint"
            value={tokenMint}
            onChange={handleTokenMintChange}
            error={errors.tokenMint}
            disableButton = {disableButton}
          />
          <InputField
            label="Enter Rate"
            id="rate"
            value={rate}
            onChange={handleRateChange}
            error={errors.rate}
            disableButton = {disableButton}
          />
          <InputField
            label="Enter Decimals"
            id="decimals"
            value={decimals}
            onChange={handleDecimalChange}
            error={errors.decimals}
            disableButton = {disableButton}
          />
          <InputField
            label="Enter Funding Account"
            id="fundingAccount"
            value={fundingAccount}
            onChange={handleFundingAccountChange}
            error={errors.fundingAccount}
            disableButton = {disableButton}
          />
          <CustomButton disableButton = {disableButton} loading = {loading} onClick={() => setOfferedTokenData()} />
        </div>
      </CustomAccordion>
    </div>
  );
}
